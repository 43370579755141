import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import EventCalendar from '../components/EventCalendar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import EventData from '../content/tournaments.json';
import EventCard, { formatEventHours} from '../components/EventCard';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { navigate } from "gatsby"
import { generateEventPageUrl } from '../utils';


//TODO make sure you get curremt year. //TODO standardize the event jsons to use event start day for multiday events?
const events = EventData.events.map((event) => {
    return {...event, 
        start: new Date(`2021-${event.start.month}-${event.start.day}T${event.start.hours}:${event.start.minutes}`),
        end: new Date(`2021-${event.end.month}-${event.end.day}T${event.end.hours}:${event.end.minutes}`),
        allday: false,
        resource: false}
  })

export default function Tournaments(props){

    const data = props.data;
    const [selectedEvent, selectEvent] = React.useState(props.pageContext ? props.pageContext.eventData : null);

    return (
        <Layout>
        <SEO title={selectedEvent ?  selectedEvent.title : 'Tournaments'} 
        image={selectedEvent ? data[selectedEvent.imageKey].childImageSharp.resize : null}
        description="compete to improve, have fun, and win prizes!"
        structuredData={selectedEvent ? selectedEvent.structuredData : ''}/>
        <Grid container direction="column" alignItems="center" style={{textAlign: 'center'}}>
            <Grid item style={{padding: '2rem'}} xs={12}>
                <Typography variant="h3" style={{color: '#243E83'}}>Conquer The 2021 Season</Typography>
                <Typography variant="h4"><i>Compete against the region with the top coaches in your corner</i></Typography>
            </Grid>
            <Grid item xs={12} sm={8} style={{padding: '2rem'}}>
                <Typography variant="paragraph">
                Blue Crabs Volleyball is gearing up to compete in the upcoming 2021 Tournament Season and you can be a part of our
                competition teams! We will be selecting highly motivated, athletic and skilled athletes with a passion to compete and
                push their limit to attend tournament events and compete against some of the best in the region. Additionally, we will
                be hosting our own local tournaments and posting those dates soon. Take a look at our
                schedule below and reach out to <a href='mailto:contact@bluecrabsvolleyball.com'>our coaching team</a> directly, or click
                on an event date and fill out the interest form. 
                </Typography>
            </Grid>
            <Grid item style={{padding: '1rem', width: '90%'}} xs={12}>
                <Typography variant="h6" style={{color: '#243E83', borderBottom: '1px solid #F78D1F', marginBottom: '15px'}}>
                    See you on the court!
                </Typography>
                <EventCalendar events={events} height={650}
                    onSelectEvent={(eventObj, e) => {navigate('/tournaments/'+generateEventPageUrl(eventObj, eventObj.title))}}/>
            </Grid>

            <Modal open={selectedEvent!=null} onClose={() => navigate('/tournaments/')} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {selectedEvent !=null && <EventCard title={selectedEvent.title} variant="popover" closePopover={() => navigate('/tournaments/')}
                    cta="Register"
                    time={{day: selectedEvent.date, hours: formatEventHours(
                        typeof selectedEvent.start.getMonth == 'function' ? {hours: selectedEvent.start.getHours(), minutes: selectedEvent.start.getMinutes()} :
                            {hours: selectedEvent.start.hours, minutes: selectedEvent.start.minutes},
                        typeof selectedEvent.end.getMonth == 'function' ? {hours: selectedEvent.end.getHours(), minutes: selectedEvent.end.getMinutes()} :
                        {hours: selectedEvent.end.hours, minutes: selectedEvent.end.minutes}
                    )}}
                    formId={selectedEvent.formId}
                    location={selectedEvent.location}
                    ageGroups={selectedEvent.ageGroups}
                    image={data[selectedEvent.imageKey].childImageSharp.gatsbyImageData}
                    shareUrl={props.location.href}
                    coaches={selectedEvent.coaches.map((coach) => {
                        return {image: data[coach.id].childImageSharp.gatsbyImageData, label: coach.name, id: coach.id}
                    })}/>}
            </Modal>
        </Grid>
        </Layout>
    )
}

export const query = graphql`
query {
  passing: file(relativePath: {eq: "events/passing.jpg"}) {
      ...cardImage
  }
  digging: file(relativePath: {eq: "events/digging.jpg"}) {
      ...cardImage
  }
  serving: file(relativePath: {eq: "events/serving.jpg"}) {
    ...cardImage
  }
  hitting: file(relativePath: {eq: "events/hitting.jpg"}) {
      ...cardImage
  }
  blocker: file(relativePath: {eq: "events/blocker.jpg"}) {
      ...cardImage
  }
  jump_serve: file(relativePath : {eq: "events/jump_serve.jpg"}){
    ...cardImage
  }
  spike: file(relativePath : {eq: "events/spike.jpg"}){
    ...cardImage
  }
  tournament: file(relativePath: {eq: "events/tournament.jpg"}){
    ...cardImage
  }
  kristen_falcinelli: file(relativePath: {eq: "kristen_falcinelli_profile.jpg"}) {
    ...cardImage
  }
  kelly_hughes: file(relativePath: {eq: "kelly_hughes_profile.jpg"}) {
    ...cardImage
  }
  enzo_lulushi: file(relativePath: {eq: "enzo_lulushi_profile.jpg"}) {
    ...cardImage
  }
  edina_blazevic: file(relativePath: {eq: "edina_blazevic_profile.jpg"}) {
    ...cardImage
  }
  jordan_mckinney: file(relativePath: {eq: "jordan_mckinney_profile.jpg"}) {
    ...cardImage
  }
}
`